import { CloseOutlined, NavigateNextOutlined } from '@mui/icons-material';
import {
	Box,
	Chip,
	Container,
	Divider,
	Fab,
	IconButton,
	Stack,
	SwipeableDrawer,
	Typography,
} from '@mui/material';
import { useRouter } from 'next/router';

import { orderDataHelper } from '~served/utils';

import { useGetCurrentDeviceCache } from '~/queries/useGetCurrentDevice';
import { Order } from '~/types/__generated/gql/graphql';

export function ActiveOrdersModal({ open, onClose }) {
	const { data: device } = useGetCurrentDeviceCache();

	if (!device) return null;

	return (
		<SwipeableDrawer
			disableSwipeToOpen
			onOpen={() => {}}
			anchor="bottom"
			open={open}
			onClose={onClose}
			sx={{
				'.MuiPaper-root': {
					maxHeight: '95vh',
					borderRadius: (t) => t.spacing(4, 4, 0, 0),
				},
			}}
		>
			<Container maxWidth={false} component={Stack} py={{ xs: 2, sm: 3 }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography variant="h6">{device._active_orders.length || 'No'} active orders</Typography>
					<Fab
						size="small"
						sx={{
							zIndex: 'unset',
						}}
						color="primary"
						onClick={onClose}
					>
						<CloseOutlined />
					</Fab>
				</Box>

				{!device._active_orders.length && (
					<Box width={1} textAlign="center">
						<img src="/images/order-success.png" width="200px" alt="all-prepared.png" />
						<Typography variant="body2" color="GrayText">
							You will see your processing orders here
						</Typography>
					</Box>
				)}

				{!!device._active_orders.length && (
					<Stack mt={{ xs: 2, sm: 3 }} gap={1} divider={<Divider sx={{ my: 1 }} />}>
						{device._active_orders.map((order) => (
							<OrderCard key={(order as Order)._id} data={order as Order} />
						))}
					</Stack>
				)}
			</Container>
		</SwipeableDrawer>
	);
}

function OrderCard({ data }: { data: Order }) {
	const { push } = useRouter();

	const { $d } = orderDataHelper(data as any);

	return (
		<Box display="flex" alignItems="center">
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 0.5,
					flexGrow: 1,
				}}
			>
				<Typography variant="body2" sx={{ '& span': { color: 'text.secondary' } }}>
					<b>{data._location.name}</b> {'\u00B7'} <span>#{data.index}</span>
				</Typography>
				<Box display="flex" gap={1}>
					<Chip
						label={
							<Box fontSize={16} display="flex" alignItems="center" gap={0.5}>
								<Typography variant="caption">{$d(data.grand_total)}</Typography>
							</Box>
						}
					/>

					{data.is_paid ? (
						<Chip color="success" component="span" label="PAID" />
					) : (
						<Chip color="error" component="span" label="UNPAID" />
					)}
				</Box>
			</Box>
			<IconButton color="primary" onClick={() => push({ pathname: '/thank-you', query: { id: data._id } })}>
				<NavigateNextOutlined />
			</IconButton>
		</Box>
	);
}
